.galleryImage{
    overflow: hidden;
    width: 100%;
    position: relative;
}

.galleryImage a{
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
}