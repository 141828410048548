
.coverImage{
    width: 100vw;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.coverImageContent{
    z-index: 5;
    color: white;
    margin: 0 0 110px 12%;
}

.coverImageContent h1{
    font-size: 72px;
    color: white;
}

.coverImageContent p{
    font-size: 30px;
}

.coverImage > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
}

.carouselControls{
    display: flex;
    width: 100px;
    justify-content: space-between;
    position: absolute;
    left: calc(50% - 50px);
    bottom: 110px;
}

.carouselIndicator{
    width: 15px;
    height: 15px;
    background: rgba(255,255,255,0.8);
    border-radius: 10px;
    cursor: pointer;
    position: relative;
}

.activeCarousel{
    top: -5px;
    left: -5px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 3px solid rgba(255,255,255,0.8);
    position: absolute;

}


.portfolioFrontPage, .serviceWrapper{
    width: 100vw;
    padding: 110px 12% 110px;
    display: flex;
    flex-direction: column;
}

.serviceWrapper{
    background: black;
}

.frontPage h2{
    font-size: 64px;
}

.serviceWrapper h2{
    color: white;
}

.portfolioLinksWrapper{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    margin-top: 50px;
    position: relative;
    overflow: hidden;
}

.portfolioLink {
    position: relative;
    overflow: hidden;
}

.portfolioLink>a{
    width: 100%;
    height: 100%;

    overflow: hidden;
}

.portfolioLink img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.mobileOverlay{
    color: white;
    position: absolute;
    z-index: 10;
    bottom: 30px;
    left: 30px;
}

.portfolioLinkOverlay{
    background: rgba(10,10,10,0.5);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(1px);
    flex-direction: column;
}

.portfolioLinkOverlay p{
    color: white;
    font-size: 24px;
}

.portfolioLinkOverlay h1{
    color: white;
    font-size: 48px;
}


.aboutMeSectionWrapper{
    background: white;
    padding: 110px 12%;
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(3, 1fr);
    position: relative;
}

.aboutMeSectionWrapper h1{
    font-size: 72px;
}

.aboutMeSectionWrapperSmall{
    position: relative;

}

.aboutMeSectionWrapper img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.aboutMeSectionWrapperLarge{
    grid-column: 1/3;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
}

.aboutMeSectionWrapperLarge h2{
    font-size: 20px;
}

.aboutMeSectionWrapperLarge p{
    padding-right: 10%;
    font-size: 18px;
    margin: 30px 0;
}

.bookingButton{
    padding: 10px 30px;
    border: 1px solid black;
    background: none;
    cursor: pointer;
}

.bookingButton h2{
    color: black
}

.bookingButton:hover{
    background: #eee;
}

.darkSection{
    color: white;
    background: rgba(0,0,0,1);
}


.clientSection{
    display: flex;
    background: black;
    padding: 110px 12% 50px;
}

.clientSection h2{
    color: white;
    font-size: 48px;
    text-align: center;
}

.clientWrapper a{
    display: flex;
    justify-content: center;
    align-items: center;
}

.clientWrapper{
    display: flex;
    justify-content: space-between;
}




.clientWrapper img{
    width: 100%;
    max-width: 150px;
}

.clientWrapper a{
    width: 12vw;
}

.clientWrapper a:hover{
    filter: brightness(0.8);
}

.services{
    display: flex;
    color: white;
    justify-content: space-around;
    align-items: flex-start;
    position: relative;
}

.services svg{
    color: white;
    width: 80px;
    height: 80px;
    margin: 30px 0;
}

.services > div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.services h3{
    font-size: 32px;
}

.services p{
    padding: 30px;
    text-align: center;
    max-width: 400px;
    font-size: 18px;
}


@media only screen and (max-width: 1750px){
    .carouselControls{
        top: calc(50% - 50px);
        left: 12%;
    }

    .carouselControls{
        flex-direction: column;
        height: 100px;
    }
}

@media only screen and (max-width: 1100px) {


    .coverImageContent > a > h1{
        font-size: 64px;
    }

    .coverImageContent p{
        font-size: 24px;
    }


    .aboutSection img{
        width: 60vw;
    }


}

@media only screen and (max-width: 900px) {
    .portfolioLinksWrapper{
        grid-template-columns: 1fr;
    }

    .portfolioLink{
        margin-bottom: 50px;
    }

    .frontPage h2 {
        font-size: 36px;
    }

    .clientWrapper{
        flex-direction: column;
        align-items: center;
    }

    .clientWrapper a{
        width: 60vw;
    }

    .aboutMeSectionWrapper{
        grid-template-columns: 1fr;
        padding: 110px 30px;
        grid-gap: 0;
    }

    .aboutMeSectionWrapper h1{
        font-size: 36px;
    }

    .coverImageContent > h1{
        font-size: 46px;
    }

    .coverImageContent p{
        font-size: 18px;
    }

    .portfolioFrontPage, .serviceWrapper{
        padding: 110px 30px;
    }

    .coverImageContent{
        margin: 0 0 110px 30px;
    }

    .carouselControls{
        left: 30px;
    }

    .mobileOverlay p{
        font-size: 24px;
    }
    .mobileOverlay h1{
        font-size: 48px;
    }

    .services{
        flex-direction: column;
    }

    .bookingButton{
        margin-bottom: 50px;
    }

    .bookingButton h2{
        font-size: 24px;
    }
}