.galleryCover{
    width: 100%;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.galleryCover h1{
    font-size: 124px;
    text-transform: capitalize;
    color: white;
    z-index: 1;
}

.galleryCover > img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.galleryCover p{
    font-size: 42px;
    z-index: 1;
    color: white;
}

.galleryCover > p:first-child{
    margin-top: 10vh;
}

.galleryImages{
    min-height: 40vh;
    padding: 110px 12%;
}

.galleryGrid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
}

.galleryGrid img{
    width: 100%;
    object-fit: contain;
}

.galleryColumn{
    display: flex;
    flex-direction: column;
}

.galleryColumn > div{ margin-bottom: 30px;}
.galleryColumn > .galleryImage:last-child{ margin-bottom: 0;}

@media only screen and (max-width: 900px){
    .galleryCover{
        height: 100vh;
        align-items: flex-start;
        justify-content: flex-end;
        padding: 100px 30px;
    }

    .galleryImages{
        padding: 110px 30px;
    }

    .galleryGrid{
        grid-template-columns: 1fr;
    }
    .galleryCover p {
        font-size: 24px;
    }

    .galleryCover h1 {
        font-size: 48px;
    }

}