@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Averia+Serif+Libre&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Crimson+Text&display=swap');
*{
    margin: 0;
    padding: 0;
    border: 0;
    border-radius: 0;
    box-sizing: border-box;
}

a{
    text-decoration: none;
    color: black;
}

h1, h2, h3, h4, h5, h6, button{
    font-family: 'Archivo Black', sans-serif;
}

ul, ol{
    list-style-type: none;
}

p, span, a, li, textarea, input{
    font-family: 'Crimson Text', serif;
}

body{
    background: #ffffff;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}