
.scrollButtonWrapper{
    width: 100px;
    height: 100px;
    position: fixed;
    bottom: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.scrollButtonWrapper img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
    animation: spin 15s infinite;
    animation-timing-function: linear;
}

.scrollButtonWrapper > div{
    width: 100%;
    position: absolute;
    height: 100%;
}

.contactMeButton{
    width: 70%;
    height: 70%;
    background: rgba(0, 0, 0, 0.52);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(9.2px);
    -webkit-backdrop-filter: blur(9.2px);
    border-radius: 50%;
    cursor: pointer;
    z-index: 10;
    border: 1px solid white;
}

.contactMeButton:hover{
    background: rgba(10,10,10,0.5);
}

.contactMeButton > svg{
    width: 50%;
    height: 50%;
}

@keyframes spin {
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}

.contactWrapper{
    width: 700px;
    background: rgba(0, 0, 0, 0.92);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(9.2px);
    -webkit-backdrop-filter: blur(9.2px);
    border: 1px solid rgba(0, 0, 0, 0.52);
    height: 60vh;
    z-index: 100;
    position: fixed;
    bottom: 110px;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px 110px;
    min-height: 700px;
}

.contactWrapperMobile{
    width: 100vw;
    height: 100vh;
    bottom: 0;
    padding: 70px 20px 50px;
}

.contactWrapperMobile h3{
    max-width: 500px;
}

.closeContact{
    width: 50px;
    height: 50px;
    position: absolute;
    background: none;
    border-radius: 50%;
    cursor: pointer;
    top: 30px;
    right: 30px;
    color: white;
}

.closeContact:hover svg{
    color: #ddd;
}

.closeContact > svg{
    width: 80%;
    height: 80%;
}

.contactWrapper h3{
    color: white;
    font-size: 36px;
    text-align: center;
}

.contactWrapper form{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    width: 100%;
    margin-top: 30px;
}

.contactWrapper form input{
    border: none;
    background: rgba(50,50,50, 0.8);
    width: 100%;
    font-size: 14px;
    color: white;
    padding: 10px 10px;
}

.contactWrapper form button{
    padding: 10px 20px;
    cursor: pointer;
    background: white;
    color: black;

}

.contactWrapper form button:hover{
    background: #ddd;

}

.contactWrapper form textarea{
    height: 10em;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    color: white;
    padding: 10px 10px;
    font-size: 14px;
    resize: none;
    background: #444444;
    background: rgba(50,50,50, 0.8);
}

.formInputWrapper h4{
    color: white;
    font-size: 14px;
    margin-bottom: 10px;
}

.errorDivContainer{
    display: flex;
    flex-direction: column;
}

.errorDivContainer p{
    position: absolute;
    top: 100%;
    color: red;
    font-size: 12px;
    margin-top: 3px;
}

.formInputWrapper{
    width: 100%;
    margin-bottom: 25px;
    position: relative;
}

.emailContact{
    width: 100%;
    color: white;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid white;
    min-height: 30px;
    position: relative;
    padding: 10px 0;
    margin-top: 20px;
}

.emailContact h1{
    font-size: 16px;
    display: flex;
    align-items: center;
}

.emailContact h1 > svg{
    margin-right: 10px;
}


.emailContact>svg{
    height: 30px;
    width: 30px;
    object-fit: contain;
    margin-right: 20px;
    position: absolute;
    left: 10px;
}

.emailContact:hover h1, .emailContact:hover svg{
    color: #ddd;
}

.messageSent{
    color: white;
    min-height: 400px;
    display: flex;
    align-items: center;
    font-size: 20px;
    max-width: 500px;
}

@media only screen and (max-width: 1200px) {

    .contactWrapperMobile{
        height: 100%;
    }

    .contactWrapper form{
        max-width: 500px;
    }

    .emailContact{
        max-width: 500px;
    }

    .emailContact h1{
        font-size: 12px;
    }
}

@media only screen and (max-width: 1100px) {
    .closeContact{
        position: fixed;
        top: 50px;
        right: 30px;
    }

    .contactWrapperMobile h3{
        margin-top: 30px;
    }

    .scrollButtonWrapper{
        width: 70px;
        height: 70px;
        bottom: 30px;
    }

    .contactWrapper form textarea{
        height: 5em;
    }
}