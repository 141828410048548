footer{
    display: flex;
    justify-content: space-between;
    height: 50px;
    padding: 0 12%;
    align-items: center;
    background: black;
    color: white;
}

.socialIcons{
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: calc(50% - 50px)

}

.socialIcons > a{
    width: 30px;
    height: 30px;
    margin: 0 10px;
}

.socialIcons svg{
    color: white;
    width: 100%;
    height: 100%;
}

.socialIcons svg:hover{
    color: #ddd;
}
@media only screen and (max-width: 900px){
    .socialIcons{
        left: calc(50% - 30px)
    }

    .socialIcons > a{
        width: 20px;
        height: 20px;
        margin: 5px;
    }

    footer p{
        font-size: 10px;
    }
}