.mobileScrollLanding{
    position: absolute;
    bottom: 40px;
    left: 30px;
    color: white;
    height: 40px;
    width: 40px;
    animation: upAndDown linear infinite 2s;
}

.mobileScrollLanding svg{
    width: 100%;
    height: 100%;
}

@keyframes upAndDown{
    0%{
        bottom: 40px;
    }
    50%{
        bottom: 50px;
    }
    100%{
        bottom: 40px;
    }
}