.photoPageWrapper{
    width: 100vw;
    height: calc(100vh - 50px);
    padding: 78px 12% 50px;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.photoWrapper {
    max-height: calc(100vh - 128px - 200px);
    width: fit-content;
    position: relative;
}

.photoWrapper p{
    font-size: 24px;
    text-align: center;
}

.photoWrapper > img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.metaPhoto{
    position: absolute;
    top: calc(100% + 20px);
    right: 0;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.photoControlForward, .photoControlBackward{
    margin: 0 10px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    z-index: 5;
}

.photoControlForward{
    right: calc(12% - 100px);
}

.photoControlBackward{
    left: calc(12% - 100px);
}

.photoControlForward > svg, .photoControlBackward > svg{
    color: black;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 900px){
    .photoControlForward > svg, .photoControlBackward > svg{
        color: black;
        height: 25px;
        width: 25px;
    }

    .photoControlForward{
        right: 10px;
    }

    .photoControlBackward{
        left: 10px;
    }

    .metaPhoto p{
        font-size: 14px;
    }
}
