/* Desktop */
.navbarWrapperDesktop {
    width: 100vw;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 12%;
    position: fixed;
    top: 0;
    display: flex;
    left: 0;
    z-index: 100;
    background: rgba(0, 0, 0, 0.92);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6.7px);
    -webkit-backdrop-filter: blur(6.7px);

}
.navbarWrapperDesktop h1{
    font-size: 54px;
    color: rgba(0, 0, 0, 1);
    color: white;
}

.navbarWrapperDesktop p{
    color: white;
}

.menuDesktop{
    margin: 0 0 0 30px;
    display: flex;
    list-style: none;
    align-items: flex-end;
}

.menuDesktop > li{
    margin-left: 30px;
    font-size: 24px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    position: relative;
    color: white;
}

.menuDesktop > li h3{
    font-size: 20px;
}

.menuDesktop > li:hover{
    color: #ddd;
}

.underlineMenu{
    width: 100%;
    border: 2px solid white;
    position: absolute;
    top: 100%;
}


@media only screen and (max-width: 1100px) {
    .navbarWrapperDesktop h1 {
        font-size: 42px;
    }

    .menuDesktop > a {
        font-size: 16px;
    }
}

/*mobile*/

.navbarWrapperMobile{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 12%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background: rgba(0,0,0,0.92);
    backdrop-filter: blur(6.7px);
    -webkit-backdrop-filter: blur(6.7px);
    width: 100vw;
}

.navbarWrapperMobile h1 {
    font-size: 36px;
    color: white;
    z-index: 1000 !important;
}

.burgerWrapper{
    width: 30px;
    height: 30px;
    position: relative;
    color: white;
    cursor: pointer;
}

.burgerWrapper svg{
    width: 100%;
    height: 100%;
}

.mobileMenuModal{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: black;
    z-index: 15;
    background: rgba(0,0,0,1);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6.7px);
    -webkit-backdrop-filter: blur(6.7px);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 150px 12%;
}

.mobileMenuModal li{
    color: white;
    font-size: 42px;
}

@media only screen and (max-width: 1100px) {
    .navbarWrapperMobile{
        padding: 10px 30px;
    }
}

.mobileMenuModal img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media only screen and (max-width: 900px) {
    .mobileMenuModal{
        padding: 150px 30px;
        color: white;
    }
}

.menuMobile a{
    color: white;
}

.menuImageCoverMobile{
    margin-bottom: 30px;
    padding: 30px;
    background: #fff;
    z-index: -1;
}

@media only screen and (max-width: 900px) and (max-height: 500px){
    .menuImageCoverMobile{
        display: none;
    }
}